<template>
  <div id="RankingList">
    <div class="top-title">
      <div>排行榜</div>
    </div>
    <div class="wrong-list">
      <div class="title">刷题数量排行榜（考试模式）</div>
      <ul>
        <li v-for="(item, index) in allListData" :key="index">
          <div>{{ index + 1 > 3 ? index + 1 : "" }}</div>
          <div><img :src="item.avatar" alt="" /></div>
          <div>{{ item.name }}</div>
          <div>{{ item.records_count }}</div>
        </li>
      </ul>
    </div>
    <div class="bottom">
      <div style="font-size: 12px; width: 40px" v-if="userObj.count > 50">
        50名外
      </div>
      <div v-else>{{ userObj.count }}</div>
      <div><img :src="userObj.list.avatar" alt="" /></div>
      <div :style="{ width: userObj.count > 50 ? '875px' : '920px' }">
        {{ userObj.list.name }}
      </div>
      <div v-if="userObj.count > 50">请加油哟！</div>
      <div :style="{ width: userObj.count > 50 ? '74px' : '99px' }">
        {{ userObj.list.records_count }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: "排行榜 - 西知题库",
  },
  data() {
    return {
      allListData: [], //用户数据
      userObj: { list: {} },
    };
  },
  methods: {
    refirsh() {
      let _this = this;

      let obj = {
        orderBy: "records",
        // page: 1,
        // limit: 20,
      };
      _this.$api.Weeklyranking(obj).then((res) => {
        _this.allListData = res.data.list;
        console.log(res.data);
      });
      _this.$api.sortme(obj).then((res) => {
        _this.userObj = res.data;
        // res.data.count = 101;
        console.log(res.data);

        this.loading.close();
      });
    },
  },
  created() {
    this.loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    this.refirsh();
  },
};
</script>

<style lang="less" scoped>
@color: #df3833;
#RankingList {
  padding-top: 50px;
  min-height: 835px;
  width: 1180px;
  margin: auto;
  .top-title {
    padding: 20px;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  .wrong-list {
    background: #fff;
    padding: 0px 20px;
    border-radius: 4px;
    .title {
      height: 90px;
      line-height: 90px;
      font-size: 16px;
      text-align: center;
    }
    ul {
      padding-bottom: 80px;
      background: #fff;
      li {
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 80px;
        padding: 0 10px;

        div {
          &:nth-child(1) {
            margin-top: 10px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            margin-right: 10px;
            font-size: 14px;
            color: #333;
          }
          &:nth-child(2) {
            margin-top: 10px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            margin-right: 10px;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }
          &:nth-child(3) {
            margin-top: 10px;
            padding-left: 10px;
            width: 800px;
            height: 40px;
            line-height: 40px;
            // border-radius: 50%;
            overflow: hidden; /*超出部分隐藏*/
            text-overflow: ellipsis; /* 超出部分显示省略号 */
            white-space: nowrap; /*规定段落中的文本不进行换行 */
          }
          &:nth-child(4) {
            width: 220px;
            text-align: right;
            font-weight: 700;
          }
        }
        &:nth-child(1) {
          div {
            &:nth-child(1) {
              background: url(../../../assets/image/rank-1.png) 0 4px no-repeat;
              background-size: 40px 40px;
            }
            &:nth-child(4) {
              color: #ffd371;
            }
          }
        }
        &:nth-child(2) {
          div {
            &:nth-child(1) {
              background: url(../../../assets/image/rank-2.png) 0 4px no-repeat;
              background-size: 40px 40px;
            }
            &:nth-child(4) {
              color: #cfcbef;
            }
          }
        }
        &:nth-child(3) {
          div {
            &:nth-child(1) {
              background: url(../../../assets/image/rank-3.png) 0 4px no-repeat;
              background-size: 40px 40px;
            }
            &:nth-child(4) {
              color: #f1b066;
            }
          }
        }
      }
    }
  }
  .bottom {
    width: 1180px;
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 80px;
    padding: 0 10px;
    position: fixed;
    background: #fff;
    bottom: 0px;
    z-index: 200;
    border-top: 1px solid #dfe1e6;

    div {
      &:nth-child(1) {
        margin-top: 10px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-right: 10px;
        font-size: 14px;
        color: #333;
        margin-left: 20px;
      }
      &:nth-child(2) {
        margin-top: 10px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-right: 10px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
      &:nth-child(3) {
        margin-top: 10px;
        padding-left: 10px;
        width: 300px;
        height: 40px;
        line-height: 40px;
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        white-space: nowrap; /*规定段落中的文本不进行换行 */
      }
      &:last-child {
        width: 220px;
        text-align: right;
        font-weight: 700;
      }
    }
  }
}
</style>